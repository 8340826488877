import { Link } from "react-router-dom"
import Stack from "react-bootstrap/Stack"
import Avatar from "@mui/material/Avatar"
import { useModalContext } from "../Context/ModalContext"
import { useDesignsContext } from "../Context/DesignsContext"
import ScheduleIcon from '@mui/icons-material/Schedule';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import Tooltip from '@mui/material/Tooltip'
import { UNDO, REDO} from '../Types/ActionTypes';
import { AUTOSAVING, LOADING, SYNCING } from '../Types/DesignStatusTypes' 
import { USER_TUTORIAL } from "../Types/MenuTypes" 
import { useEffect } from "react"
import { useSocketioContext } from "../Context/SocketioContext"
import { useAuth } from "../Context/AuthContext"
import TransparentTextField from "./TransparentTextField"
import LogoutDropdown from './LogoutDropdown';

const ActionBar = () => {
  const { profileModalShow } = useModalContext()
  const { socket } = useSocketioContext()
  const { currentDesign, designRename, currentDesignActiveUsers, setCurrentDesignActiveUsers, undoDesign, redoDesign } = useDesignsContext()
  const { snowflakeAccount } = useAuth()

  useEffect(() => {
    if(socket !== null && socket !== undefined) {
      socket.on('updateDesignActiveUsers', (usersList) => {
        setCurrentDesignActiveUsers(usersList)
      })
    }
  }, [socket])

  // Function to handle onClick event on multiple actions in the action bar
  const handleClick = async (option) => {
    switch(option) {
      case UNDO:
        undoDesign()
        break
      case REDO:
        redoDesign()
        break
      default:
        break
    }
  }

  return (
    <Stack direction="horizontal" gap={2} className="px-4 bg-primary text-white">
      <Link to="/" >
        <img 
          className="navbar-logo" 
          src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoicm9jaGVcL2FjY291bnRzXC8xNFwvNDAwMDM3MlwvcHJvamVjdHNcLzQxXC9hc3NldHNcLzY1XC8xMzIxM1wvOWVjMTgwNGE3NDM1OGUwMGIxNGQwZDZlOGQ4MTU1NTctMTYzNjAxOTIwMy5lcHMifQ:roche:O8QaBGBzvcr77Gys2UXPW7VHKtvskcz-lIlBsf4_6R0" alt="Roche Brand Logo"
          role="button"
        />
      </Link>
      <div className="ms-4">
        {currentDesign.designName !== USER_TUTORIAL 
          ? (
            <TransparentTextField 
              inputText={currentDesign?.businessName }
              updateTextHandler={value => designRename(value)}
              authorized={currentDesign?.authorized}
            />
          )
          : `SANDBOX ENVIRONMENT in ${snowflakeAccount}`}
      </div>
      {currentDesign.designName !== USER_TUTORIAL && (
        <div role="button" className='ms-4 pe-1'>
          <Tooltip 
            title={currentDesignActiveUsers?.length > 0 ? (
              currentDesignActiveUsers.join(', ')
            ) : 'No more users working on this design'} 
            placement="bottom" 
            arrow
          >
            <PeopleAltIcon />
          </Tooltip>
        </div>
      )}
      
      {currentDesign.authorized && (
        <>
          <div role="button" className="ms-auto" onClick={() => handleClick(UNDO)}>
            <Tooltip title="Undo" placement="bottom-end" arrow>
              <UndoRoundedIcon />
            </Tooltip>
          </div>
          <div role="button" className='ms-1 pe-1' onClick={() => handleClick(REDO)}>
              <Tooltip title="Redo" placement="bottom-start" arrow>
                <RedoRoundedIcon />
              </Tooltip>
          </div>
          <div className="vr"></div>
        </>
      )}
      <div className={currentDesign.authorized ? 'ms-2' : 'ms-auto'}>
        {currentDesign.savingStatus}
      </div>
        {
          (currentDesign.savingStatus === AUTOSAVING || 
            currentDesign.savingStatus === LOADING || 
            currentDesign.savingStatus === SYNCING) ? (
            <>
              <div className="spinner-border spinner-border-sm ms-1 me-2">
                <span class="visually-hidden">Loading...</span>
              </div>
            </>
          ) : (
            <div className="me-2">
              <ScheduleIcon />
            </div>
          )
        }
      <div>
        {/* <Link to="/profile" onClick={profileModalShow}>
          <Avatar 
            alt="avatar pic" 
            src="https://lh3.googleusercontent.com/a/default-user=s96-c" 
            sx={{ width: 30, height: 30 }}
          />
        </Link> */}
        <LogoutDropdown top="45" />
      </div>
    </Stack>
  )
}

export default ActionBar;